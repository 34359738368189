// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-components-blog-post-js": () => import("./../../../src/components/blog-post.js" /* webpackChunkName: "component---src-components-blog-post-js" */),
  "component---src-components-category-js": () => import("./../../../src/components/category.js" /* webpackChunkName: "component---src-components-category-js" */),
  "component---src-components-pages-tools-js": () => import("./../../../src/components/pages/tools.js" /* webpackChunkName: "component---src-components-pages-tools-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-confirm-subscription-js": () => import("./../../../src/pages/confirm-subscription.js" /* webpackChunkName: "component---src-pages-confirm-subscription-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-tools-js": () => import("./../../../src/pages/tools.js" /* webpackChunkName: "component---src-pages-tools-js" */),
  "component---src-pages-tools-web-development-js": () => import("./../../../src/pages/tools/web-development.js" /* webpackChunkName: "component---src-pages-tools-web-development-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */)
}

